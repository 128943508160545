<template lang="pug">
  div#gallery
    .gallery
      .gallery-backdrop
      .enforce-max-width
        b-row
          b-col(cols="12")
            b-row
              b-col(cols="12")
                b-row#gallery-images
                  b-col(cols="12", sm="4")
                    img(v-on:click="viewImage($event)", src='../assets/images/gallery/cabbage.jpg' alt='Cabbage')
                    img(v-on:click="viewImage($event)", src='../assets/images/gallery/pelican-large.jpg' alt='Pelican - Large')
                    img(v-on:click="viewImage($event)", src='../assets/images/gallery/squirrel.jpg' alt='Squirrel')
                  b-col(cols="12", sm="4")
                    img(v-on:click="viewImage($event)", src='../assets/images/gallery/owls.jpg' alt='Owls')
                    img(v-on:click="viewImage($event)", src='../assets/images/gallery/seashell.jpg' alt='Seashell')
                    img(v-on:click="viewImage($event)", src='../assets/images/gallery/gnatcatchers.jpg' alt='Gnatcatchers')
                  b-col(cols="12", sm="4")
                    img(v-on:click="viewImage($event)", src='../assets/images/gallery/california-flowers.jpg' alt='California Flowers')
                    img(v-on:click="viewImage($event)", src='../assets/images/gallery/pelican-small.jpg' alt='Pelican - Small')
    b-modal#gallery-modal(v-model="modalShow")
      div#gallery-modal-wrapper
        b-img(:src="src", fluid)
        b-icon-x.close-btn(scale="2", v-on:click="closeImage()")

</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      src: null,
      modalShow: false
    };
  },
  methods: {
    viewImage(e) {
      this.src = e.target.src;
      this.modalShow = true;
    },
    closeImage() {
      this.modalShow = false;
    }
  },
  mounted() {
    document.getElementById("main").classList.add("dark-bg");
    document.getElementById("main").classList.add("dark-bg-mobile");
  }
};
</script>

<style lang="less">
.modal-dialog {
  max-width: max-content;
  max-height: 100vh;
  padding: 12px;
  margin: 0 auto;
}
.modal-content {
  background: none;
  border: none;
  border-radius: 0;
  width: auto;
}
.modal-body {
  padding: 0;
}
.modal-header {
  display: none;
}
.modal-footer {
  display: none;
}
.modal.fade {
  opacity: 0;
  transition: 0.5s all 0s;
  padding-left: 0px !important;
  width: 100%;
  max-width: 100%;
  text-align: center;
}
.modal.fade.show {
  transform: scale(1) !important;
  opacity: 1;
}
.modal-backdrop {
  opacity: 0.8;
}
#gallery-modal-wrapper > img {
  max-height: calc(100vh - 24px);
}
</style>

<style scoped lang="less">
.gallery {
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  padding: var(--nav-height) 30px 40px !important;
  background: var(--semi-transparent-bg);
}
#gallery:not(.fade-enter-active),
#gallery:not(.fade-leave-active),
#gallery:not(.fade-enter) {
  .gallery-backdrop {
    display: block;
    background-color: var(--semi-transparent-bg);
    position: absolute;
    height: 100%;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: -1;
  }
}
#gallery-images > div > img {
  margin-bottom: 1.5rem !important;
  cursor: pointer;
  width: 100% !important;
  border-radius: 0.25rem !important;
}
.close-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 2rem;
  opacity: 0.7;
  color: var(--dark-bg-color);
  transition: all 0.25s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
@media (max-width: 767px) {
}
</style>
